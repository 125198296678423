(function ($) {
  Drupal.behaviors.productReplenishmentSelect = {
    attach: function (context) {
      // Add replenishment menu event if enable_replenishment=true in config.
      if (!Drupal.settings.common || !Drupal.settings.common.has_replenishment) {
        return null;
      }

      var $addBtn = $('.js-add-to-cart', '.js-product');
      var $replenishment = $('.js-product-replenishment', context);
      var $replenishmentSelect = $('.js-replenishment-select', context);
      var $replenishmentLink = $('.js-product-replenishment-link', $replenishment);
      var replenishmentContent;

      // When the replenishment select changes, update the add-to-bag
      // button's data-replenishment value.
      $replenishmentSelect.on('change', function () {
        $addBtn.attr('data-replenishment', this.value);
      });

      // Set add-to-bag default replenishment value to 0.
      $addBtn.attr('data-replenishment', 0);

      $replenishmentLink.once().on('click', function (e) {
        e.preventDefault();
        if (typeof site.templates !== 'undefined' && typeof site.templates.product_replenishment_overlay !== 'undefined') {
          replenishmentContent = site.template.get({ name: 'product_replenishment_overlay' });
          generic.overlay.launch({
            html: replenishmentContent
          });
        }
      });
    }
  };
})(jQuery);
